import React, {useState, useEffect} from 'react'
import {motion} from "framer-motion";
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import Hero from '../components/Hero'
import Header from '../components/Header'
import Works from '../components/Works'
import Footer from '../components/Footer'
import Modal from '../components/Modal'
import Apollo11ABI from '../components/Apollo11ABI.json'
import ScrollTop from '../components/ScrollTop'

import { useWeb3React } from "@web3-react/core"
import { InjectedConnector } from '@web3-react/injected-connector'
import { ethers } from 'ethers'
import { render } from 'react-dom';

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
})

const CONTRACT_ADDRESS = "0x9006f8bd58C1057F5c8A81424a62B7F279C1d814";
const MINT_FEE = "0.2" // 0.2 ETH

function Home() {

    const LoadingTransition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
    const [minting, setMinting] = useState(false)

    useEffect(() => {
        const headerAnim = document.querySelector('.header-anim')
        const heroTitle = document.querySelectorAll('.hero-title')
        
        setTimeout(() => {
            headerAnim.classList.add('header-anim-on')
        }, 1000)

        setTimeout(() => {
            heroTitle.forEach((item, index) => {
                setTimeout(() => {
                    item.classList.add('hero-title-on')
                }, 200 * index) 
            })
        }, 1500)
    }, [])

    const onScrollBottomAnim = () => {
        const animBot = document.querySelectorAll('.anim-bot')

        animBot.forEach(item => {
            let itemPosition = item.getBoundingClientRect();

            if(itemPosition.top >= 0 && itemPosition.top <= window.innerHeight - 250) {
                item.classList.add('item-anim-bot')
            }
        })   
    }

    useEffect(() => {
        window.addEventListener("scroll", onScrollBottomAnim);
        return () => window.removeEventListener("scroll", onScrollBottomAnim);
    }, [])

    const { active, account, library, connector, activate, deactivate } = useWeb3React()

    async function connect() {
        const { ethereum } = window;

        if (!ethereum) {
            alert("Install an ethereum wallet in order to connect. Metamask Download Instructions - https://metamask.io/download.html ");
            return;
        }

        try {
            await activate(injected)
            setupEventListener()
        } catch (ex) {
            console.log(ex)
        }
    }

    async function disconnect() {
        try {
            deactivate()
        } catch (ex) {
            console.log(ex)
        }
    }

    const setupEventListener = async () => {
        try {
          const { ethereum } = window;
    
          if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, Apollo11ABI.abi, signer);
    
            connectedContract.on("NewNFTMinted", (tokenId) => {
              console.log(tokenId.toNumber())
              alert(`Congratulations! Your NFT has been minted successfully! It can take a max of 10 min to show up on OpenSea`)
            })
            console.log("Event listener setup for NewNFTMinted")
          } else {
            console.log("Ethereum object doesn't exist")
          }
        } catch (error) {
          console.log(error)
        }
    }

    const mintNFTFromContract = async () => {
        try {
          const { ethereum } = window;
  
          if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner()
            const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, Apollo11ABI.abi, signer)
            
            let overrides = {
                // To convert Ether to Wei
                value: ethers.utils.parseEther(MINT_FEE)            
            };
            let nftTxn = await connectedContract.safeMint(account, overrides)
  
            // console.log("Mining... please wait")
            setMinting(true);
            await nftTxn.wait()
            console.log(nftTxn)
            setMinting(false)
          } else {
            console.log("Ethereum object doesn't exist")
            alert("Connect an ethereum wallet in order to mint.");
          }
        } catch (error) {
          console.log(error)
        }
    }

    return(
        <div>
            <motion.div 
                initial={{x:0}}
                animate={{x:'-100%'}}
                exit={{x:0}}
                transition={LoadingTransition}
                className="page-trans">
            </motion.div>
            <ScrollTop />
            <Header />
            <Hero>
                <h1 className="big-title hero-title">Launching ZerosAndOnes Apollo 11 Collection!</h1>
                <h2 className="sub-title hero-title">ZerosAndOnes Apollo 11 Collection is an opportunity to own an NFT from the Apollo 11 mission to the moon!</h2>
                <h2 className="sub-title hero-title" style={{ marginTop: "30px"}} ><HashLink to="/#portfolioSection">Checkout Apollo11 Collection</HashLink></h2>
                <h2 className="sub-title hero-title" style={{ marginTop: "30px"}}><Link to='/about' >More about ZerosAndOnes</Link></h2>
            </ Hero>
            <Works>
            <div className="flex flex-col items-center justify-center">
                    <button variant="primary" onClick={connect}>{active ? (
                                                                                "Connected: " +
                                                                                String(account).substring(0, 6) +
                                                                                "..." +
                                                                                String(account).substring(38)
                                                                                ) : (
                                                                                <span>Connect Wallet</span>
                                                                            )}</button>
                    {active && <button onClick={disconnect} className='form-input-btn'>Disconnect</button>}
            </div>
            {
                minting ? <button disabled={true} style={{ marginTop: "20px", marginBottom: "20px"}} >Minting...</button> : <button onClick={mintNFTFromContract} className="cta-button mint-button" style={{ marginTop: "20px", marginBottom: "20px"}}>Mint NFT</button>
            }
            </ Works>
        </div>
    )

}

export default Home