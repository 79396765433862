import React, {useEffect} from 'react'
import Web3 from 'web3'
import { Web3ReactProvider } from '@web3-react/core'
import {Switch, Route, useLocation, withRouter, HashRouter} from 'react-router-dom'
import {AnimatePresence} from 'framer-motion';

import Home from './pages/Home'
import SingleWork from './pages/SingleWork'
import About from './pages/About'
import News from './pages/News'
import SingleNews from './pages/SingleNews'
import Contact from './pages/Contact'
import Page404 from './pages/404'

import ScrollTop from './components/ScrollTop'
import Works from './components/Works';

function getLibrary(provider) {
  return new Web3(provider)
}

function App() {

  const location = useLocation()

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div>
          <AnimatePresence initial={true} exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path='/'><Home /></Route>
              <Route path='/about'><About /></Route>
              <Route ><Page404 /></Route>
            </Switch>
          </AnimatePresence>
      </div>
    </Web3ReactProvider>
  )

}

const WithRouterApp = withRouter(App);
export default WithRouterApp
