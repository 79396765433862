import React from 'react'
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function AboutText(){

    return(
        <section id="heroSection" className="section">
            <div className="container-fluid-small">
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-left anim-bot">
                        <h2 className="big-title">Art collections inspired by <br /> zeros and ones.</h2>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-right anim-bot">
                        <div className="story">
                            <p className="big-p">We live in a world where we are surrounded by zeros and ones all around us, whether it be increasingly smarter homes or mobile phones which literally execute zeros and ones or the binary decisions we face all the time like whether to carry an umbrella on a gloomy day or not.
                            ZerosAndOnes is a crypto arts project focused on bringing these zeros and ones of our lives onto the blockchain.</p>
                        </div>
                    </div>

                    <div className="col-xl-3"></div>

                    <div className="col-xl-3"></div>

                </div>
            </div>
        </section>
    )
    
}

export default AboutText