import React from 'react'
import {Link} from 'react-router-dom'

function Footer() {

    const date = new Date().getFullYear()

    const socialLinks = [
        {
            name: 'Facebook',
            url: 'https://www.facebook.com/'
        },
        {
            name: 'Instagram',
            url: 'https://www.instagram.com/'
        },
        {
            name: 'Twitter',
            url: 'https://www.twitter.com/'
        }
    ]

    return(
        <footer>
        </footer>
    )

}

export default Footer