import React from 'react'
import {Link} from 'react-router-dom'

function WorkItem(props){

    return(
        <div className={`col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 portfolio-box anim-bot`}>
            <div className="cancel-anchor"></div>
                <div className="portfolio-image">
                    <img src={props.image} alt="" />
                </div>
        </div>
    )

}

export default WorkItem