import React, {useState, useEffect} from 'react'

import WorkItem from '../elements/WorkItem'

import works from '../data/Works'
import { Link } from 'react-router-dom'

import burn from '../images/BURN_BABY_BURN--MASTER_IGNITION_ROUTINE.png';
import stable_orbit from '../images/STABLE_ORBIT.png'
import star_tables from '../images/STAR_TABLES.png'
import interbank_communication from '../images/INTER-BANK_COMMUNICATION.png'
import alignment_routines from '../images/INFLIGHT_ALIGNMENT_ROUTINES.png'
import alarm_and_abort from '../images/ALARM_AND_ABORT.png'

function Works(props) {

    return(
        <section id="portfolioSection" className="section">
            <div className="container-fluid-small">

                <h1 className="anim-bot big-title">Apollo 11 to the moon!</h1>
                <p className="big-p">ZerosAndOnes Apollo 11 Collection is a ERC-721 compliant set of NFTs on the Ethereum blockchain generated from the source code of the Apollo 11 mission to the moon.
                This collection is an opportunity to own an NFT which contains code that put humans on the moon for the first time in history!
                Each NFT in this collection is built using zeros and ones from the machine code of a sub-module.
                After excluding the non-code related sub-modules, this is a collection of only 172 NFTs and each NFT can be minted for 0.20Ξ.
                Below are some of the images for NFTs from the collection. White pixels represent zero and black pixels represents one from the machine code.</p>
                <div className="row">
                    <div className="col-12">
                        {props.children}
                    </div>
                </div>
                <div className="row portfolio-row anim-bot-big">
                    <WorkItem title="Image 1" image={burn} />
                    <WorkItem title="Image 2" image={stable_orbit} />
                    <WorkItem title="Image 3" image={star_tables}/>
                    <WorkItem title="Image 4" image={interbank_communication}/>
                    <WorkItem title="Image 5" image={alignment_routines}/>
                    <WorkItem title="Image 6" image={alarm_and_abort}/>
                </div>
                <p className="big-p">This Collection is built from the Comanche 055 and Luminary 099 mission softwares for the Apollo Guidance Computer(AGC) present on the Command Module(CM) and Lunar Module(LM) of the Apollo 11 mission respectively.
                Each NFT in this collection represents a sub-module from either of these modules and has been built using the machine code comprising of zeros and ones generated from the corresponding AGC source code of that sub-module.</p>
                <p className="big-p">All the minted NFTs will be available on Opensea for resale. Apollo 11 source code is Open Source and can be found on <a target="_blank" href="https://github.com/chrislgarry/Apollo-11/">Github</a>.
                A big shout out to <a target="_blank" href="http://www.ibiblio.org/apollo/">Virtual AGC Project</a> for all the amazing documentation on the Apollo missions.
                Reach out to <a href="mailto:contact@zerosandones.art">contact@zerosandones.art</a> for any concerns or issues.</p>
            </div>
        </section>
    )

}

export default Works